/** @jsx jsx */
import { jsx, Box, Input, Button } from 'theme-ui'
import { useState, ChangeEvent } from 'react'

const LOCATION = [
  { value: 'kyoto', locationName: '京都' },
  { value: 'osaka', locationName: '大阪' },
  { value: 'hokkaido', locationName: '北海道' },
  { value: 'nara', locationName: '奈良' },
  { value: 'fukuoka', locationName: '福岡' },
  { value: 'sapporo', locationName: '札幌' },
  { value: 'okinawa', locationName: '沖縄' },
  { value: 'hiroshima', locationName: '広島' },
  { value: 'kobe', locationName: '神戸' },
  { value: 'nagoya', locationName: '名古屋' },
]

const JOB_TYPE = [
  { value: 'developer', jobName: '開発者' },
  { value: 'designer', jobName: 'デザイナー' },
  { value: 'frontendengineer', jobName: 'フロントエンドエンジニア' },
  { value: 'backendengineer', jobName: 'バックエンドエンジニア' },
  { value: 'infrastructureengineer', jobName: 'インフラエンジニア' },
  { value: 'sreengineer', jobName: 'SREエンジニア' },
  { value: 'pm', jobName: 'PM' },
  { value: 'cloud', jobName: 'クラウド' },
  { value: 'mobileengineer', jobName: 'スマホアプリエンジニア' },
  { value: 'gameengineer', jobName: 'ゲームエンジニア' },
  { value: 'pmd', jobName: 'PDM' },
  { value: 'PMO', jobName: 'PMO' },
  { value: 'securityengineer', jobName: 'セキュリティエンジニア' },
  { value: 'serverengineer', jobName: 'サーバエンジニア' },
  { value: 'datascientist', jobName: 'データサイエンティスト' },
  { value: 'designer', jobName: 'デザイナー' },
]

const WEB_DEVELOPMENT_ROLE = [
  { key: 'html', language: 'HTML' },
  { key: 'css', language: 'CSS' },
  { key: 'javascript', language: 'JavaScript' },
  { key: 'typescript', language: 'TypeScript' },
  { key: 'python', language: 'Python' },
  { key: 'ruby', language: 'Ruby' },
  { key: 'php', language: 'PHP' },
  { key: 'java', language: 'Java' },
  { key: 'csharp', language: 'C#' },
  { key: 'go', language: 'Go' },
  { key: 'rust', language: 'Rust' },
  { key: 'swift', language: 'Swift' },
  { key: 'kotlin', language: 'Kotlin' },
  { key: 'html5', language: 'HTML5' },
  { key: 'css3', language: 'CSS3' },
  { key: 'sql', language: 'SQL' },
  { key: 'plsql', language: 'PL/SQL' },
  { key: 'graphql', language: 'GraphQL' },
  { key: 'bash', language: 'Bash' },
  { key: 'shell', language: 'Shell Scripting' },
  { key: 'nodejs', language: 'Node.js' },
  { key: 'lua', language: 'Lua' },
  { key: 'r', language: 'R' },
  { key: 'scala', language: 'Scala' },
  { key: 'haskell', language: 'Haskell' },
  { key: 'elixir', language: 'Elixir' },
  { key: 'clojure', language: 'Clojure' },
  { key: 'groovy', language: 'Groovy' },
  { key: 'perl', language: 'Perl' },
  { key: 'dart', language: 'Dart' },
  { key: 'objectivec', language: 'Objective-C' },
  { key: 'vhdl', language: 'VHDL' },
  { key: 'ada', language: 'Ada' },
  { key: 'fsharp', language: 'F#' },
  { key: 'actionscript', language: 'ActionScript' },
  { key: 'tcl', language: 'Tcl' },
  { key: 'c', language: 'C' },
  { key: 'cplus', language: 'C++' },
  { key: 'cminus', language: 'C-' },
  { key: 'vba', language: 'VBA' },
  { key: 'latex', language: 'LaTeX' },
  { key: 'xml', language: 'XML' },
  { key: 'json', language: 'JSON' },
  { key: 'yaml', language: 'YAML' },
  { key: 'markdown', language: 'Markdown' },
  { key: 'xsl', language: 'XSLT' },
  { key: 'fortran', language: 'Fortran' },
  { key: 'prolog', language: 'Prolog' },
  { key: 'lisp', language: 'Lisp' },
]

const WORK_TYPE = [
  { key: 0, workType: '特になし（リモートでもオフラインでも可）' },
  { key: 1, workType: 'オフィスでの業務がメイン' },
  { key: 2, workType: '一部リモート' },
  { key: 3, workType: 'リモートがメイン（打合せのみオフィス）' },
  { key: 4, workType: '完全リモート（打合せもオンライン）' },
]

export const Filter = () => {
  const [searchForm, setSearchForm] = useState({
    searchText: '',
    occupation: '',
    region: '',
    workingType: '',
    skillList: [],
  })

  const Handler = {
    setTextForm: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchForm((element) => {
        return {
          ...element,
          [e.target.name]: e.target.value,
        }
      })
    },
    setSelectBox: (e: ChangeEvent<HTMLSelectElement>) => {
      setSearchForm((element) => {
        console.log(e)
        return {
          ...element,
          [e.target.name]: e.target.value,
        }
      })
    },
  }

  const filteringHandler = (key: string) => {
    if (searchForm.skillList.includes(key)) {
      setSearchForm((element) => {
        return {
          ...element,
          skillList: element.skillList.filter((o) => o !== key),
        }
      })
    } else {
      setSearchForm((element) => {
        const jobList = element.skillList
        if (searchForm.skillList.length > 4) {
          jobList.shift()
        }
        return {
          ...element,
          skillList: [...jobList, key],
        }
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(4px)',
        backgroundColor: '#fff',
        width: ['90%', '90%', '50%', '30%', '20%'],
        padding: '20px',
        fontSize: ['12px', '15px', '18px'],
        marginLeft: ['0px', '0px', '20px'],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          fontSize: ['14px', '16px', '20px'],
        }}
      >
        <h3 sx={{ margin: '0px' }}>案件詳細検索</h3>
      </Box>

      <Box sx={{ marginBottom: '10px' }}>
        <label style={{ fontSize: '14px' }}>フリーワード検索</label>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Input
            sx={{
              flexGrow: 1,
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '15px',
            }}
            value={searchForm.searchText}
            name="searchText"
            onChange={(e) => Handler.setTextForm(e)}
            placeholder="検索ワード入力"
          />
          <Button
            sx={{
              backgroundColor: '#d3d3d3',
              color: '#000',
              border: 'none',
              borderRadius: '6px',
              marginLeft: '10px',
              padding: '8px',
              height: '100%',
              fontSize: ['12px', '10px', '15px'],
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
              textWrap: 'nowrap',
              '&:hover': {
                backgroundColor: '#b0b0b0',
              },
            }}
          >
            検索
          </Button>
        </Box>
      </Box>

      {/* 職種フィルター */}
      <Box sx={{ marginBottom: '20px' }}>
        <label style={{ fontSize: '14px' }}>職種</label>
        <Box sx={{ marginTop: '10px' }}>
          <select
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '14px',
            }}
            name="occupation"
            value={searchForm.occupation}
            onChange={(e) => Handler.setSelectBox(e)}
          >
            <option value="">選択してください</option>
            {JOB_TYPE.map((jobValue, index) => (
              <option key={index} value={jobValue.value}>
                {jobValue.jobName}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      {/* 地域フィルター */}

      <Box sx={{ marginBottom: '20px' }}>
        <label style={{ fontSize: '14px' }}>地域</label>
        <Box sx={{ marginTop: '10px' }}>
          <select
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '14px',
            }}
            name="region"
            value={searchForm.region}
            onChange={(e) => Handler.setSelectBox(e)}
          >
            <option value="">選択してください</option>
            {LOCATION.map((locationValue, index) => (
              <option key={index} value={locationValue.value}>
                {locationValue.locationName}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      {/* 働き方 */}
      <Box sx={{ marginBottom: '20px' }}>
        <label style={{ fontSize: '14px' }}>働き方</label>
        <Box sx={{ marginTop: '10px' }}>
          <select
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: '14px',
            }}
            name="workingType"
            value={searchForm.workingType}
            onChange={(e) => Handler.setSelectBox(e)}
          >
            <option value="">選択してください</option>
            {WORK_TYPE.map(({ workType }, index) => (
              <option key={index} value={workType}>
                {workType}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      <Box sx={{ marginBottom: '50px' }}>
        <Box>
          <p sx={{ margin: '0px', padding: '0px', fontSize: '14px' }}>
            スキルタグ
          </p>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              marginTop: '10px',
              height: '300px',
              overflow: 'overlay',
            }}
          >
            {WEB_DEVELOPMENT_ROLE.map((value, index) => (
              <Box
                sx={{
                  display: 'inline-block',
                  color: 'rgb(93, 100, 125)',
                  fontWeight: 'bold',
                  backgroundColor: '#ffffff',
                  border: searchForm.skillList.includes(value.key)
                    ? '2px solid rgb(151, 158, 180)'
                    : '2px solid rgb(234, 238, 251)',
                  borderRadius: '20px',
                  fontSize: '12px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
                key={index}
                onClick={() => filteringHandler(value.key)}
              >
                {value.language}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
